import classnames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

type ButtonProps = {
  title?: string;
  isLoading?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = ({ title, isLoading, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      className={classnames(
        'font-outfit font-bold flex gap-2 justify-center items-center text-[18px] text-white bg-primaryRed w-full h-[71px] rounded-[15px] disabled:border disabled:text-gray1 disabled:bg-white disabled:pointer-events-none',
        props.className
      )}
      disabled={isLoading || props.disabled}
    >
      {isLoading && <div className="w-6 h-6 border-4 border-t-gray1 border-gray-1/30 rounded-full animate-spin"></div>}
      {title}
    </button>
  );
};

export default Button;
